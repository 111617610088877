import React, { useState } from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import ReactMapboxGl from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { markers } from "../../../ressources/constant";
import Mark from "./Mark";

const token =
  "pk.eyJ1IjoiZ21uYWNjb3VudCIsImEiOiJja2x3ZTNjZDEwamVrMnZvNnB0dWhrdnJzIn0.lxLKAiKTPrvwa_5SUAOURA";

const MapBox = () => {
  const [viewPort, setViewPort] = useState({
    width: "100%",
    height: 600,
    latitude: 43.81704260740509,
    longitude: 4.328994565255,
    zoom: 13,
  });

  return (
    <div className={classnames("map", { isMobile })}>
      <ReactMapboxGl
        {...viewPort}
        mapboxApiAccessToken={token}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={(next) => setViewPort(next)}
      >
        {markers.map((marker, index) => (
          <Mark key={index} marker={marker} />
        ))}
      </ReactMapboxGl>
    </div>
  );
};

export default MapBox;
