import classnames from "classnames";

const FeedBack = ({ className, visible, ...props }) => {
  const { children, ...rest } = props;
  const vis = visible ? "visible" : "";

  return (
    <div className={classnames("form-feed-back", className, vis)} {...rest}>
      {children}
    </div>
  );
};

export default FeedBack;
