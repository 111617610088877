import classnames from "classnames";

const Label = ({ htmlFor, className, ...props }) => {
  const { children, ...rest } = props;
  return (
    <label
      htmlFor={htmlFor}
      className={classnames("form-label", className)}
      {...rest}
    >
      {children}
    </label>
  );
};
export default Label;
