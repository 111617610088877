import React, { useState } from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import { useToggle } from "../helpers/customHooks";
import { menu } from "../ressources/constant";

import logo from "../assets/icons/logo.svg";
import burger from "../assets/icons/burger.svg";

const Header = () => {
  const [selected, setSelected] = useState(0);
  const [extend, toggleExtend] = useToggle(false);

  return (
    <header id="header">
      <div className={classnames("nav", { isMobile, extend })}>
        <div className="h-title">
          <div>
            <a className="relative sm:left-5 md:left-10 top-3" href="./">
              <img src={logo} width={260} alt={logo} />
            </a>
          </div>
          {!isMobile ? (
            <div className="h-menu">
              {menu.map((elem, index) => (
                <div key={index} className="h-item">
                  <a
                    href={elem.target}
                    onClick={() => setSelected(elem.label)}
                    className={elem.label === selected ? "selected" : ""}
                  >
                    {elem.label}
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="div-burger">
                <img
                  onClick={toggleExtend}
                  src={burger}
                  alt="burger"
                  className="h-burger"
                />
              </div>
            </>
          )}
        </div>

        {isMobile ? (
          <div className={classnames("sub-menu", { extend })}>
            {menu.map((elem, index) => (
              <div key={index}>
                <a
                  href={elem.target}
                  onClick={() => {
                    setSelected(elem.label);
                    toggleExtend();
                  }}
                  className={elem.label === selected ? "selected" : ""}
                >
                  {elem.label}
                </a>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
