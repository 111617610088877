import React from "react";

import Router from "./routes/Route";

import "react-toastify/dist/ReactToastify.css";

import "./assets/scss/index.scss";

const App = () => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
