import React, { useRef } from "react";
import { Marker } from "react-map-gl";
import Rater from "react-rater";

import "react-rater/lib/react-rater.css";
import Popover from "../Popover";

import pointer from "../../../assets/icons/pointer.svg";
import localisation from "../../../assets/icons/localisation.svg";
import clock from "../../../assets/icons/clock.svg";

const Mark = ({ marker }) => {
  const target = useRef(null);

  return (
    <>
      <Marker
        longitude={marker.coordinates.longitude}
        latitude={marker.coordinates.latitude}
      >
        <div className="marker-base">
          <div ref={target}>
            <img
              style={{ zIndex: 4 }}
              className="marker-icon"
              src={pointer}
              alt="marker"
            />
            <Popover target={target}>
              <div className="marker">
                <div className="marker-title">
                  <h5>{marker.name} </h5>
                  {marker.rate ? (
                    <>
                      {marker.rate}{" "}
                      <Rater
                        total={5}
                        rating={marker.rate}
                        interactive={false}
                      />
                    </>
                  ) : null}
                </div>
                {marker.address ? (
                  <div className="mk-item marker-address">
                    <img src={localisation} alt="localisation" />
                    {marker.address}
                  </div>
                ) : null}
                {marker.times && marker.times.length ? (
                  <div
                    className={`mk-item marker-times mh${marker.times.length}`}
                  >
                    <img src={clock} alt="clock" />
                    <div>
                      {marker.times.map((time, index) => (
                        <div key={index}> {time}</div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {marker.other && marker.other.length ? (
                  <div className="mk-item marker-other">
                    {marker.other.map((other, index) => (
                      <div key={index}> {other} </div>
                    ))}
                  </div>
                ) : null}
                <div className="mk-triangle" />
              </div>
            </Popover>
          </div>
        </div>
      </Marker>
    </>
  );
};

export default Mark;
