import React from "react";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

import logo from "../assets/icons/logo.svg";
import Button from "./common/Button";

const Footer = () => {
  return (
    <footer className={classnames("footer", { isMobile })}>
      <div className={classnames({ isMobile })}>
        <div>
          <a href="/">
            <img className="footer-log" src={logo} alt="logo" />
          </a>
          <p>© {new Date().getFullYear()} Garde-meuble Nîmes</p>
        </div>
        <div className="footer-btn">
          <Button tag="a" href="#tarif" className="shadow">
            Nous contacter
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
