import { useState, useEffect } from "react";

export const useToggle = (init = false) => {
  const [state, setState] = useState(init);
  const toggle = () => setState(!state);
  return [state, toggle];
};

export const useScroll = (limit) => {
  const [state, setState] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      setState(window.scrollY > limit);
    };
  }, []);

  return state;
};
