import React from "react";

import { modules } from "../ressources/constant";

const Modules = () => {
  return (
    <div className="flex justify-around flex-wrap md:flex-nowrap w-full md:w-10/12 md:mx-auto mt-12 md:-mt-16">
      {modules.map((module, index) => (
        <div
          key={index}
          className="flex md:flex-col w-1/2 md:w-1/5 my-1 py-2 z-10"
        >
          <div className="my-auto md:flex md:justify-center">
            <div className="mx-2 rounded-full bg-white border border-gray-800 shadow-xl p-2 md:p-6">
              <img src={module.icon} width={60} alt="icon" />
            </div>
          </div>
          <div className="w-full my-auto">
            <h6 className="text-center md:mt-2 text-md md:text-xl underline md:no-underline">
              {module.title}
            </h6>
            <p className="text-center text-xs md:text-md md:w-3/4 md:mx-auto md:mt-2 text-gray-600">
              {module.desc}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Modules;
