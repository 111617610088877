import React from "react";
import classnames from "classnames";

const Button = ({ className, id, value, disabled, color, size, ...props }) => {
  const { children, tag, ...rest } = props;

  const Tag = tag || "button";
  return (
    <Tag className={classnames("btn", className, color, size)} {...rest}>
      {children}
    </Tag>
  );
};

export default Button;
