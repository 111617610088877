import classnames from "classnames";

const Input = ({
  className,
  type,
  id,
  name,
  placeholder,
  onChange,
  onBlur,
  value,
  invalid,
  ...rest
}) => {
  const inva = invalid ? "invalid" : "";

  return (
    <input
      className={classnames("form-input", className, inva)}
      type={type}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  );
};

export default Input;
