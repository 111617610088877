import classnames from "classnames";

const Col = ({ className, md, ...props }) => {
  const { children, ...rest } = props;

  const m = md ? `md-${md}` : "";

  return (
    <div className={classnames("col", m, className)} {...rest}>
      {children}
    </div>
  );
};
export default Col;
