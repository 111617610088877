import React, { useState, useRef } from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import * as Yup from "yup";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";

import { Col } from "./grid";
import { FeedBack, Input, Group, Label } from "./common/form";
import Button from "./common/Button";
import { FaSpinner } from "react-icons/all";

const url = "https://garde-meuble-nimes.fr/form/post.php";
const key = "6LdT7XIaAAAAAJCNu2Ak3HlueBG_SoiVQjNkNegt";

const Form = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    name: "",
    firstName: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Veuillez renseigner ce champ."),
    email: Yup.string()
      .email("L'adresse email est invalide.")
      .required("Veuillez renseigner ce champ."),
    phone: Yup.string().required("Veuillez renseigner ce champ."),
  });

  const handleSubmit = (values, { resetForm }) => {
    setIsSubmitting(true);
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(key, { action: "submit" });

      const requestOptions = {
        mode: "cors",
        header: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify({ values, token }),
      };

      const data = await fetch(url, requestOptions)
        .then((r) => r.json())
        .catch(() => {
          toast.error("Une erreur est survenue");
        });

      if (data.error == null) {
        resetForm({ initialValues });
        toast.success(
          "Nous avons bien reçu votre demande. Nous allons vous recontacter sous 48h."
        );
      } else {
        toast.error(data.error || "Une erreur est survenue");
      }
      setIsSubmitting(false);
    });
  };

  return (
    <div id="tarif" className={classnames("form", { isMobile })}>
      <div className={classnames("form-title", { isMobile })}>
        <h2 className="text-secondary">Votre garde-meuble à Nîmes</h2>
        <p>
          Recevez le meilleur tarif pour votre garde-meuble sécurisé à Nîmes.
          Comparateur 100% gratuit et sans engagement.
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form className={classnames({ isMobile })} onSubmit={handleSubmit}>
            <Group row>
              <Col md={6}>
                <Label htmlFor="name">
                  Nom <em>*</em>
                </Label>
                <Input
                  type="text"
                  placeholder="Nom"
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  invalid={touched.name && !!errors.name}
                />
                <FeedBack visible={touched.name && !!errors.name}>
                  {errors.name}
                </FeedBack>
              </Col>
              <Col md={6}>
                <Label htmlFor="name">Prénom</Label>
                <Input
                  type="text"
                  placeholder="Prénom"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  invalid={touched.firstName && !!errors.firstName}
                />
                <FeedBack visible={touched.firstName && !!errors.firstName}>
                  {errors.firstName}
                </FeedBack>
              </Col>
            </Group>
            <Group row>
              <Col md={12}>
                <Label htmlFor="email">
                  Email <em>*</em>
                </Label>
                <Input
                  type="text"
                  placeholder="exemple@mail.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  invalid={touched.email && !!errors.email}
                />
                <FeedBack visible={touched.email && !!errors.email}>
                  {errors.email}
                </FeedBack>
              </Col>
            </Group>
            <Group row>
              <Col md={12}>
                <Label htmlFor="phone">
                  Téléphone <em>*</em>
                </Label>
                <Input
                  type="tel"
                  placeholder="00 00 00 00 00"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  invalid={touched.phone && !!errors.phone}
                />
                <FeedBack visible={touched.phone && !!errors.phone}>
                  {errors.phone}
                </FeedBack>
              </Col>
            </Group>
            <Group>
              <Button className="shadow" disabled={isSubmitting} type="submit">
                {!isSubmitting ? (
                  "Recevoir mon tarif gratuitement"
                ) : (
                  <span className="flex">
                    Chargement{" "}
                    <FaSpinner size={24} className="ml-4 animate-spin" />
                  </span>
                )}
              </Button>
              <p className="text-xs italic text-center mt-2 opacity-70">
                En envoyant ce formulaire, j'accepte que les données envoyées
                soient utilisées dans le cadre de la demande de contact et de la
                relation commerciale qui peut en suivre.
              </p>
            </Group>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default Form;
