import classnames from "classnames";

const Group = ({ className, row, ...props }) => {
  const { children, ...rest } = props;
  const Row = row ? "row" : "";

  return (
    <div className={classnames("form-group", className, Row)}>{children}</div>
  );
};

export default Group;
