import React from "react";
import Slider from "react-slick";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import { testimonials } from "../ressources/constant";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderItem = ({ item }) => (
  <div className={classnames("slider-item", { isMobile })}>
    <h3> {item.name} </h3>
    <p className="slider-p"> {item.message} </p>
  </div>
);

const Testimonials = () => {
  const settings = {
    dots: true,
    speed: 900,
    autoplaySpeed: 5000,
    autoplay: true,
    draggable: false,
    arrows: false,
  };

  return (
    <div className={classnames("testimonials", { isMobile })}>
      <div className="testimonials-title">
        <h2> Témoignages de nos utilisateurs</h2>
      </div>
      <div className="testimonials-slider">
        <Slider {...settings}>
          {testimonials.map((item, index) => (
            <SliderItem key={index} item={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
