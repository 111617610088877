import React from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import Header from "../components/Header";
import Map from "../components/Map";
import Modules from "../components/Modules";
import Form from "../components/Form";
import Reservation from "../components/Reservation";
import Footer from "../components/Footer";
import { useScroll } from "../helpers/customHooks";

import scrollTop from "../assets/icons/scroll-top.svg";
import Testimonials from "../components/Testimonials";

const Home = () => {
  const displayTop = useScroll(100);

  return (
    <>
      <Header />

      <main>
        <Map />
        <Modules />
        <Form />
        <Reservation />
        <Testimonials />
      </main>

      <Footer />

      <a href="#top" className={classnames("btn-top", { hide: !displayTop })}>
        <img src={scrollTop} alt="top" />
      </a>

      <div className={classnames("background-vertical", { isMobile })} />
    </>
  );
};

export default Home;
