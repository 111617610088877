import padlock from "../assets/icons/padlock.svg";
import clock from "../assets/icons/clock.svg";
import random from "../assets/icons/random.png";
import wind from "../assets/icons/wind.png";
import mapMarkerAlt from "../assets/icons/map-marker-alt.png";

export const menu = [
  {
    target: "#tarif",
    label: "Tarif",
  },
  {
    target: "#resa",
    label: "Louer un box",
  },
  {
    target: "#size",
    label: "Taille de box",
  },
];

export const markers = [
  {
    name: "Mondialbox Nîmes",
    address: "Impasse du Mas Confort, 1184 Avenue Marechal Juin, 30900 Nîmes",
    web: "https://www.mondialbox.com/fr",
    phone: "07 88 55 50 43",
    times: ["Ouvert 24h/24"],
    coordinates: {
      longitude: 4.343032801837774,
      latitude: 43.8194653417452,
    },
    other: ["offre -20%"],
  },
  {
    name: "LocaKase Nimes",
    rate: 4.9,
    address: "145 Impasse de la Villa Gallo Romaine, 30000 Nîmes",
    phone: "07 88 55 50 43",
    web: "https://www.locakase.fr/garde-meuble-occitanie/box-stockage-nimes",
    times: [
      "lundi : 13h30-17h30",
      "Du mardi au vendredi : 9h-12h / 13h30-17h30",
      "Samedi : 9h-12h",
    ],
    coordinates: {
      longitude: 4.354847292785489,
      latitude: 43.81325701124764,
    },
  },
  {
    name: "HOMEBOX Nîmes",
    rate: 4.3,
    address: "Zac Du Mas De Vignolles, Place Andre Bazile, 30000 Nîmes",
    phone: "04 82 29 43 31",
    web:
      "https://www.homebox.fr/liste-des-gardes-meubles/garde-meuble-nimes.html",
    times: ["Du mardi au samedi : 9h-12h30 / 14h-18h"],
    coordinates: {
      longitude: 4.358440320227217,
      latitude: 43.80800837441511,
    },
  },
  {
    name: "Atout-Box Nîmes Carémeau",
    rate: 4.5,
    address: "162 Rue Louis Lumière, 30900 Nîmes",
    web: "https://www.atout-box.fr/ou-nous-trouver/agence-nimes-caremeau/",
    phone: "04 48 19 39 00",
    times: ["Du lundi au vendredi : 8h-13h / 14h-20h"],
    coordinates: {
      longitude: 4.312341676492203,
      latitude: 43.81394904317648,
    },
  },
  {
    name: "Atout-Box Nîmes Saint-Césaire",
    rate: 4.8,
    address: "836 Avenue Joliot Curie, 30900 Nîmes",
    phone: "04 48 19 39 00",
    web: "https://www.atout-box.fr/ou-nous-trouver/agence-nimes-saint-cesaire/",
    times: [
      "Du lundi au vendredi : 8h-13h / 14h-20h",
      "samedi : 8h-13h / 14h-18h",
    ],
    coordinates: {
      longitude: 4.316241190071859,
      latitude: 43.80872851880773,
    },
    other: ["Camion offert", "Accès 24h/24"],
  },
];

export const modules = [
  {
    icon: padlock,
    title: "La sécurité",
    desc: "Caméras 24h/24, cadenas et alarme",
  },
  {
    icon: clock,
    title: "La flexibilité",
    desc: "Sans engagement de temps, accès 7j/7",
  },
  {
    icon: random,
    title: "Stockage pour tous",
    desc: "Surface de stockage de 1m² à 50m²",
  },
  {
    icon: wind,
    title: "Un lieu sain",
    desc: "Locaux propres, secs et ventilés",
  },
  {
    icon: mapMarkerAlt,
    title: "La proximité",
    desc: "Un box de stockage proche de chez vous",
  },
];

export const compareGrid = [
  {
    left: {
      cont: "📐\n" + "3 m3\n",
      size: "12",
    },
    right: [
      "Vous pouvez stocker environ 20 cartons",
      "L’équivalent d’un petit studio",
    ],
  },
  {
    left: {
      cont: "📐\n" + "11 m3\n",
      size: "15",
    },
    right: [
      "Possibilité d’entreposer 55 cartons",
      "Idéal pour les petits appartements",
    ],
  },
  {
    left: {
      cont: "📐\n" + "19 m3\n",
      size: "18",
    },
    right: [
      "Capacité de stockage jusqu’à 120 cartons",
      "Box de stockage pour les grands appartements",
    ],
  },
  {
    left: {
      cont: "📐\n" + "38 m3\n",
      size: "24",
    },
    right: [
      "De la place pour 240 cartons environ",
      "Soit l’équivalent d’une maison ",
    ],
  },
];

export const testimonials = [
  {
    name: "Marine D.",
    message:
      "Comparateur très efficace. J’ai pu trouver un box dans un garde-meuble très rapidement. Le local correspondait parfaitement à mes attentes et le conseiller très sympathique et arrangeant. Vous pouvez y aller les yeux fermés !",
  },
  {
    name: "François. T.",
    message:
      "J’ai utilisé leur service pour mon déménagement à Nîmes. J’y ai laissé mes meubles pendant 3 mois le temps de signer le nouveau bail. Service au top, rien à redire. Le centre est sécurisé et très propre. Petit +: les chariots sont vraiment pratiques pour déplacer ses meubles. 👌\n",
  },
  {
    name: "David P.",
    message:
      "J’ai déménagé il y a peu dans un nouvel appartement qui n’a pas de cave. J’avais donc plein de cartons qui trainaient chez moi. Louer un garde-meuble a été une très bonne décision. Tous mes cartons encombrants sont désormais rangés dans un garde-meuble. J’y ai accès quand je veux et mon appart est libéré !",
  },
  {
    name: "Camille T.",
    message:
      "J’ai loué mon logement durant un semestre pour effectuer mon stage à Bordeaux. La location d’un garde-meuble m’a vraiment été utile. J’ai pu y stocker les meubles de mon studio. 👍 En revenant, les meubles étaient intacts, j’ai pu les récupérer sans soucis. Mention spéciale à la conseillère qui s’est occupée de mon dossier. Très gentille, accessible et professionnelle. Alors merci beaucoup !!! 🥰",
  },
];
