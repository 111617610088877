import React from "react";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

import Button from "./common/Button";
import MapBox from "./common/map/MapBox";

const Map = () => (
  <div className="map-cont">
    <div className="background-horizontal-top" />

    <MapBox />

    {!isMobile ? (
      <>
        <div className="rectangle" />
        <div className="triangle" />
      </>
    ) : (
      <>
        <div className="rectangle-mobile" />
        <div className="triangle-mobile" />
      </>
    )}

    <div className={classnames("map-title", { isMobile })}>
      <h1>Garde-meuble à Nîmes</h1>
      <p>
        Notre comparateur vous aidera à trouver les meilleurs garde-meuble à
        Nîmes. Trouvez votre espace de stockage sécurisé à partir de 8€TTC/m3.
      </p>
    </div>
    <div className={classnames("map-btn", { isMobile })}>
      <Button
        size="lg"
        tag="a"
        href="#tarif"
        color="secondary"
        className="shadow"
      >
        Obtenir mon tarif
      </Button>
    </div>
  </div>
);

export default Map;
