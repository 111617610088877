import React from "react";
import { isMobile } from "react-device-detect";
import classnames from "classnames";

import { compareGrid } from "../ressources/constant";

import img from "../assets/images/box-stockage-montpellier-securisé-cadenas.jpg";
import img2 from "../assets/images/photo-box-stockage-montpellier-ouvert.jpg";
import Button from "./common/Button";

const Reservation = () => {
  return (
    <>
      <div id="resa" className={classnames("resa", { isMobile })}>
        <div className="w-90 m-auto resa-titlte">
          <h2>Comment se passe la réservation d’un box sur Nîmes ?</h2>
          <p>
            Un{" "}
            <a
              className="resa-a"
              target="_blank"
              href="https://www.atout-box.fr/"
            >
              espace de rangement à louer{" "}
            </a>
            peut se révéler très utile. Que ce soit dans le cadre d’un
            déménagement lorsque son logement d'arrivée n’est pas prêt. Ou
            simplement le souhait d’avoir accès à un espace de stockage
            supplémentaire pour son logement actuel quand on accumule les
            cartons. Les raisons peuvent être multiples. <br />
            <br /> Rassurez-vous, la réservation d’un garde-meuble est très
            simple. Tout d’abord, vous choisissez la taille de votre box parmi
            un large choix de volume. Ensuite, vous serez accompagné tout le
            long de la souscription au service. Et enfin, il ne vous reste plus
            qu’à vous installer ! Stocker ses meubles dans un garde-meuble est
            chose aisée. Pour votre plus grande confort, les espaces de stockage
            mettent à disposition des outils de manutention en libre service.
            Vous retrouverez par exemple des chariots en libre service
            permettant de transporter vos encombrants du parking jusqu’à votre
            garde-meuble personnel. Les centres proposent également des
            monte-charges pour faciliter l’accès à votre espace de stockage
            sécurisé. Lorsque vous n’avez plus besoin de votre espace
            supplémentaire vous pourrez mettre fin à votre contrat tout aussi
            facilement que lors de votre souscription. Il suffira, en général,
            d’envoyer un préavis par mail ou par téléphone. Et ce, une quinzaine
            de jours avant le départ.
          </p>
        </div>

        <div className={classnames("resa-img", { isMobile })}>
          <img src={img} alt="un homme ouvre le cadenas d’un box sécurisé" />
          <img src={img2} alt="photo d’un box de garde-meuble ouvert à Nîmes" />
        </div>
      </div>
      <div id="size" className={classnames("chose", { isMobile })}>
        <div className={classnames("chose-title", { isMobile })}>
          <h2>Comment choisir la taille de votre garde-meuble ?</h2>
          <p>
            Choisir la taille de son box n’est pas chose évidente. Et pourtant
            il est important de savoir quel volume est le plus adapté à son
            besoin. Car le prix d’un box dans un garde-meuble à Nîmes varie en
            fonction de sa taille. En faisant le bon choix de volume vous
            paierez donc le prix juste.
          </p>
          <br />
          <p className="text-center">
            Afin de vous aider à faire votre choix, nous vous mettons à
            disposition le tableau ci-dessous.
          </p>
        </div>
        <div className={classnames("chose-grid", { isMobile })}>
          <div className="t-head">
            <div className={classnames("left", { isMobile })}>
              <span className="text-bold">TAILLE </span> de box
            </div>
            <div className={classnames("right", { isMobile })}>
              <span className="text-bold"> CAPACITÉ </span> de stockage
            </div>
          </div>
          <div className="t-container">
            {compareGrid.map(({ left, right }, index) => (
              <div key={index} className="t-body">
                <div className={classnames("t-item icon left", { isMobile })}>
                  <p style={{ fontSize: `${left.size}px`, margin: 0 }}>
                    {left.cont}
                  </p>
                </div>
                <div className={classnames("t-item right", { isMobile })}>
                  <ul>
                    {right.map((li, index) => (
                      <li key={index}>{li}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={classnames("chose-bottom", { isMobile })}>
          <p>
            Il existe d’autres cubages intermédiaires. Pour plus d'infos,
            n’hésitez pas à demander de l’aide à nos conseillers. Ils sont
            habitués à recevoir ce genre de demande et vous aiderons à estimer
            l’espace de stockage qui vous est nécessaire.
          </p>

          <div className="text-center">
            {!isMobile ? (
              <a
                className="bg-secondary text-white shadow-md hover:shadow-lg transition rounded px-5 py-3 text-lg tracking-wide"
                href="#tarif"
              >
                Demander de l'aide pour estimer la taille de son garde-meuble
              </a>
            ) : (
              <div>
                <p className="text-center">
                  Demander de l'aide pour estimer la taille de son garde-meuble
                </p>
                <div className="mt-3">
                  <a
                    href="#tarif"
                    className="mt-5 bg-secondary text-white shadow-md hover:shadow-lg transition rounded px-5 py-3 text-lg tracking-wide"
                  >
                    Demander de l'aide
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
