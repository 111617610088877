import React, { useEffect, useState } from "react";

const Popover = ({ target, ...props }) => {
  const { children, ...rest } = props;
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    target.current.addEventListener("mouseenter", () => setDisplay(true));
    target.current.addEventListener("mouseleave", () => setDisplay(false));
  }, []);

  useEffect(() => console.log("display", display, []));

  return <div {...rest}>{display ? children : null}</div>;
};

export default Popover;
